/* #root {
  width: 100%;
  min-height: 100vh;
} */

.auth-bg {
  background: linear-gradient(225deg, rgba(22, 192, 220, 0) 60%, rgba(22, 192, 220, 0.8) 100%), linear-gradient(45deg, rgba(182, 68, 153, 0) 60%, rgba(182, 68, 153, 0.8) 100%), #264162;
  background-size: contain;
}

html {
  height: unset !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 60px #3f5c7e inset !important;
    background-color: #3f5c7e !important;
    background-clip: content-box !important;
}
